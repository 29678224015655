var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "CyberSequizy",
  metaDescription: "CyberSequizy, Cyber Security quizes",
  metaKeyWords: "Cybersequizy, cybersecurity, cyber, security, Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://admin.cybersequizy.com/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  questionTimerSeconds: 15,
  guesstheTimerSeconds: 30,
  levelWinCheckPoint: 30, // Above 30% is required to Clear the Quiz Level
  maxWinningCoins: 4, // This will give user the coins if user will clear the level
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  addCorrectAnswerScore: 2, // This will increase the user score after each correct answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line
  DefaultCountrySelectedInMobile: "us", //Default Country Selected in Mobile Login Screen
  Guessthewordhintcoin: 5,

  //Firebase Configurations

  apiKey: "AIzaSyDY7XdNLIOqF1PO-A73lUqBtCGXE6iyumQ",
  authDomain: "cybersequizy.firebaseapp.com",
  projectId: "cybersequizy",
  storageBucket: "cybersequizy.appspot.com",
  messagingSenderId: "200570445292",
  appId: "1:200570445292:web:88dc440658b9f80f9574c9",
  measurementId: "G-D86R4VWN4D",

  //footer area
  companytext: "CyberSequizy, Cyber Security quizes to practice your knowledge about Cyber Security",
  addresstext: "No info yet",
  phonenumber: "No info yet",
  email: "no info yet",
  facebooklink: "https://www.facebook.com/",
  instagramlink: "https://www.instagram.com/",
  linkedinlink: "https://www.linkedin.com/",
  weblink: "https://cybersequizy.com/",
  companyname: "CyberSequizy.",
};

export default config;
